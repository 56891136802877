import "../App.css";
import React, { useState, useEffect } from "react";

import { getTimings } from "../utils/apiMaster";

import Timer from "./Timer";
import Iframe from "./Iframe";
import PrayerTimesTable from "./PrayerTimesTable";

function Home() {
    const [allPrayerData, setAllPrayerData] = useState(null);
    const [iftarData, setIftarData] = useState(null);
    const [sehriData, setSehriData] = useState(null);
    const [loading, setLoading] = useState("Loading...");

    useEffect(() => {
        const fetchTimings = async () => {
            const timings = await getTimings();
            await setAllPrayerData(timings);
            setIftarData({
            date: timings.prayerTimes.date.gregorian.date.replace(/-/g, "/"),
            time: timings.prayerTimes.timings.Sunset,
            });
            setSehriData({
            date: timings.prayerTimes.date.gregorian.date.replace(/-/g, "/"),
            time: timings.prayerTimes.timings.Fajr,
            })
        };

        fetchTimings();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading("Please ensure you have enabled location on your device.");
        }, 3000);
        return () => clearTimeout(timer);
    });

    return (
        <div className="App">
          {allPrayerData && iftarData && sehriData ? (
            <>
              <Timer
                iftarData={iftarData}
                sehriData={sehriData}
                currentLocation={allPrayerData.location.currentLocation}
              />
              {allPrayerData ? (
                <div>
                  <PrayerTimesTable prayerTimes={allPrayerData.prayerTimes} />
                </div>
              ) : (
                <Iframe
                  title="loading times"
                  src="https://giphy.com/embed/559nyYPxdHpJDlex5V"
                />
              )}
            </>
          ) : (
            <div className="loading">
              <Iframe
                title="loading"
                src="https://giphy.com/embed/n1KAZ8ydmwlskkvQEJ"
              />
              <div>
                <h2>{loading}</h2>
              </div>
            </div>
          )}
          <div className="footer">
            <h2>
              Made by{" "}
              <a
                href="https://github.com/tahsinocity/iftar-timer"
                target="_blank"
                rel="noopener noreferrer"
              >
                @tahsinocity
              </a>{" "}
            </h2>
          </div>
        </div>
      );

}

export default Home;